/* eslint-disable */
<template>
  <v-card>
    <v-card-title>
      <h3>Próximas carreras</h3>
    </v-card-title>
    <v-card-text>
      <!--<h3 v-if="races.length <= 0">No hay carreras próximamente</h3>
      <v-list three-line v-else>
        <template v-for="(item, index) in races">
          <v-divider v-if="index % 2 != 0" :key="index"></v-divider>
          <router-link :to="'/race/' + item.id" class="listTitle">
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title" class="listTitle"></v-list-item-title>
                <v-list-item-subtitle v-html="item.subtitle" class="listTitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </template>
      </v-list>-->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "soonRaces",
  props: ['races']
}
</script>

<style scoped>
.listTitle {
  text-decoration: none !important;
}
</style>