<template>
  <v-app id="inspire">
    <v-navigation-drawer  v-model="drawer" :clipped="true"
       app light style="background: white" v-if="$route.path == '/account' || $route.path == '/inscriptions' || $route.path == '/clubs' || $route.path == '/addclub' ">
      <v-list dense>
        <v-list-item-group :color="dark_blue">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-on:click="logout">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="logged" class="headerTop" app fixed clipped-left color="primary" dark>
      <router-link to="/" style="color:white; text-decoration: none">
        <div class="d-flex align-center">
          <h1 color="white">Slotify </h1>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <router-link  to="/newcompetition">
        <v-btn text>
          <span class="mr-2">Añadir competicion</span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </router-link>
     
      <router-link to="/account">
        <v-btn text>
          <span class="mr-2">Mi Cuenta</span>
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </router-link>

      <v-btn v-on:click="logout()" text>
        <span class="mr-2">Salir</span>
        <v-icon>mdi-door</v-icon>
      </v-btn>
    </v-app-bar>
    
    
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: 'App',

  data: () => ({
    logged: false,
    drawer: null,
    items:[]
  }),
  created() {
    if (this.$session.exists())
      this.logged = true;
    if(this.logged == false)
      this.$router.push('/login')
     this.items = [ { icon: 'mdi-account', text: 'Mis datos', route: '/account' },
            { icon: 'mdi-cards', text: 'Mis inscripciones', route: '/inscriptions' },
            { icon: 'mdi-home', text: 'Mis clubs', route: '/clubs' },]
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push('/login');
      location.reload();
    }
  }
};
</script>
<style scoped>
.headerTop {
  background: linear-gradient(90deg, rgba(0, 255, 0, 1) 0%, rgba(9, 121, 9, 1) 35%, rgba(2, 43, 2, 1) 100%);
}
</style>
