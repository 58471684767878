import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import VueSocialSharing from 'vue-social-sharing'
import VueGlobalVariable from "vue-global-var";
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)
Vue.use(VueSession);
Vue.use(VueSocialSharing);
Vue.use(VueAxios, axios);
Vue.use(VueGlobalVariable, {
  globals: {
    version: "1.0.0beta",
    api_url: "https://slotyfy.com/apiback/client/",
    primaryColor:"green",
    secundaryColor:""
  }
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
