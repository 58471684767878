<template>
  <v-container class="mt-5">
    <!-- <v-row class="mb-5">
      <v-col class="d-flex align-center" cols="12" :sm="showsub > 0 ? 6 : 12">
        <v-select v-model="paddingDirection" :items="categories" class="pr-2" :color="primaryColor"
          label="¿Qué tipo de carrera buscas?" />
      </v-col>
      <v-col class="d-flex" cols="12" md="6" v-if="showsub > 0">
        <v-select v-model="marginDirection" :items="getsubcat()" :color="primaryColor" class="pr-2"
          label="Subcategoría" />
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <actual-races :races="races" />
      </v-col>
      <v-col cols="12" md="6">
        <soon-races :races="soonraces" />
      </v-col>
    </v-row>-->
    <v-row class="mt-5">
      <v-col cols="12">
        <h2>Selecciona campeonato</h2>
      </v-col>
      <v-col cols="4" v-for="competition in competitions" v-bind:key="competition._id">
        <router-link :to="'/competition/' + competition._id" style="text-decoration: none;">
          <v-card class="mx-auto " max-width="370" :height="300">
            <v-card-title>
              <v-img :src="competition.photoURL" :aspect-ratio="16 / 9" width="340" />
              <h3 class="titleCard" :color="primaryColor">{{ competition.name }} </h3>
            </v-card-title>
            <v-card-text class="subtitleTravel">
              <v-row>
                <v-col cols="6">
                  <div class=" subtitle-1">
                    <b>Categoría: </b> {{ getType(competition.type) }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="subtitle-1">
                    <b>Escala: </b> {{ getScale(competition.scale) }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
//import ActualRaces from "@/components/dash/actualRaces";
//import SoonRaces from "@/components/dash/soonRaces";

export default {
  name: 'Home',

  /* components: {
     SoonRaces, ActualRaces,
   },*/
  data() {
    return {
      categories: ['ver todas', 'rally', 'raid', 'velocidad'],
      showsub: 0,
      subcategoriesVel: ['todas', 'gt-am', 'gt-pro', 'gr.c', 'lmp', 'gr.5', 'dtm'],
      subcategoriesRall: ['todas', 'n', 'sn', 'wrc', 'wrc3d', 'src'],
      marginDirection: 'todas',
      paddingDirection: 'ver todas',
      races: [],
      soonraces: [],
      competitions: [],
      types: [{ value: 0, text: 'Rally' }, { value: 1, text: 'GT' }, { value: 3, text: 'LMP' }, { value: 4, text: 'Gr.5' }, { value: 5, text: 'Gr.C' }, { value: 6, text: 'Raid' }],
      scales: [{ value: 0, text: '1/32' }, { value: 1, text: '1/24' }],
    }
  },
  mounted() {
    this.getSoonRaces();
    this.getCurrentRaces();
    this.getCompetitions();
  },
  watch: {
    paddingDirection: function (newSel) {
      if (newSel === 'velocidad')
        this.showsub = 1
      else this.showsub = 2
    }
  },
  methods: {
    getSoonRaces() {
      this.axios.get(this.api_url + 'getsoonraces').then(response => {
        response.data.raceList.forEach(raceitem => {
          this.soonraces.push({ id: raceitem._id, title: raceitem.name, subtitle: this.getParseDate(raceitem.initDate), avatar: raceitem.posterURL })
        })
      })
    },
    getCurrentRaces() {
      this.axios.get(this.api_url + 'getcurrentraces').then(response => {
        response.data.raceList.forEach(raceitem => {
          this.races.push({ id: raceitem._id, title: raceitem.name, subtitle: this.getRestantTime(raceitem.endDate), avatar: raceitem.posterURL })
        })
      })
    },
    getCompetitions() {
      this.axios.get(this.api_url + 'getcompetitions').then(response => {
        this.competitions = response.data.competitionsList
      })
    },
    getRestantTime(t) {
      const t2 = new Date(t)
      const calctime = (t2.getTime() - new Date().getTime()) / 1000;
      if (calctime > 0) return calctime
      else return 'Prueba finalizada'
    },
    getParseDate(t) {
      const t2 = t.split("T")
      const t3 = t2[1].split(".")
      const t4 = t2[0].split("-")
      return t4[2] + '/' + t4[1] + '/' + t4[0] + ' ' + t3[0]
    },
    getsubcat() {
      if (this.showsub === 1) return this.subcategoriesVel
      else return this.subcategoriesRall
    },
    getType(type) {
      return this.types[parseInt(type)].text
    },
    getScale(scale) {
      return this.scales[parseInt(scale)].text
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }

}
</script>
