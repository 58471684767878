import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashView from "@/views/DashView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dash',
    name: 'dash',
    component: DashView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/loginView.vue')
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/registerView.vue')
  },
  {
    path: '/race/:raceid',
    name: 'race',
    component: () => import('../views/RaceView.vue')
  },
  {
    path: '/currentrace/:raceid',
    name: 'currentrace',
    component: () => import('../views/CurrentRaceView.vue')
  },
  {
    path: '/newcompetition',
    name: 'new competition',
    component: () => import('../views/NewCompetition.vue')
  },
  {
    path: '/competition/:competitionid',
    name: 'campeonado',
    component: () => import('../views/Competition.vue')
  },
  {
    path: '/editcompetition/:competitionid',
    name: 'edit competition',
    component: () => import('../views/EditCompetition.vue')
  },
  {
    path: '/newrace/:competitionid',
    name: 'new race',
    component: () => import('../views/NewRace.vue')
  },
  {
    path: '/editrace/:raceid',
    name: 'edit race',
    component: () => import('../views/EditRace.vue')
  },
  {
    path: '/raceinfo/:raceid',
    name: 'race',
      component: () => import('../views/Race.vue')
  },
  {
    path: '/account',
    name: 'cuenta',
    component: () => import('../views/ProfileData.vue')
  },
  {
    path: '/inscriptions',
    name: 'inscripciones',
    component: () => import('../views/Inscriptions.vue')
  },
  {
    path: '/clubs',
    name: 'clubs',
    component: () => import('../views/clubs.vue')
  },
  {
    path: '/addclub',
    name: 'nuevo club',
    component: () => import('../views/addclub.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
