<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="6">
       <actual-races :races="races" />
        </v-col> <v-col cols="6">
        <soon-races :races="soonraces" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActualRaces from "@/components/dash/actualRaces";
import SoonRaces from "@/components/dash/soonRaces";
export default {
  name: "DashView",
  components: {SoonRaces, ActualRaces},
  data() {
    return {
      races: [{id:1,
        avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
        title: 'Rally Terraslot',
        subtitle: this.generateRandom()
      }, {divider: true},
        {id:2,
          avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
          title: 'Rally Terraslot',
          subtitle: this.generateRandom()
        }, {divider: true},
        {id:3,
          avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
          title: 'Rally Terraslot',
          subtitle: this.generateRandom()
        }
      ],
      soonraces: [{id:1,
      avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
      title: 'Rally Terraslot',
      subtitle: 'Fecha: 30-09-2022'
    }, {divider: true},
      {id:2,
        avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
        title: 'Rally Terraslot',
        subtitle: 'Fecha: 30-09-2022'
      }, {divider: true},
      {id:3,
        avatar: 'https://res.cloudinary.com/grupowapps/image/upload/v1625073737/terraslot_eohl3o.jpg',
        title: 'Rally Terraslot',
        subtitle: 'Fecha: 30-09-2022'
      }
    ]
    }
  },
  methods:{
    generateRandom(min = 10880, max = 30202) {
      let difference = max - min;
      let rand = Math.random();
      rand = Math.floor(rand * difference);
      rand = rand + min;
      return rand;
    }
  }

}
</script>

<style scoped>

</style>