<template>
  <v-card>
    <v-card-title><h3>Carreras en curso</h3></v-card-title>
    <v-card-text>
       <!--<h3 v-if="races.length <= 0">No hay carreras próximamente</h3>

      <v-list three-line v-else>
        <template v-for="(item,index) in races">
          <v-divider
              v-if="index%2!=0"
              :key="index"
          ></v-divider>
          <router-link :to="'/currentrace/'+item.id" class="listTitle" >
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <countdown :tcount="item.subtitle"></countdown>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </template>
      </v-list>-->
    </v-card-text>
  </v-card>
</template>

<script>
//import Countdown from "@/components/dash/countdown";
export default {
  name: "actualRaces",
 // components: {Countdown},
  props: ['races'],
  data() {
    return {
      timerCount: 29880
    }
  },
  watch: {

    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  methods: {
    secondsToHms(d) {
      setTimeout(() => {
        d = d - 1000
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + ":" : "";
        var mDisplay = m > 0 ? m + ":" : "";
        var sDisplay = s > 0 ? s + "" : "00";
        return 'Tiempo restante: ' + hDisplay + mDisplay + sDisplay;
      }, 1000);    },

  }
}
</script>

<style scoped>
.listTitle {
  text-decoration: none !important;
}
</style>